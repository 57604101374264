<template>
  <div class="Unamortizedpar">
    <div class="top-titles">
      <div class="title">
          <span>待审核商家列表</span>/待审核商家详情
      </div>
     
     <div class="top-box">
          <div class="top-head">
            <div class="top-head-img">
            <img v-if="listpar.urlImage !== ''" :src="upLoadUrl+listpar.urlImage" alt="" />
            <img v-if="listpar.urlImage == ''" src="../../assets/image/touxiang.png" alt="" />
            </div>
            <span>{{listpar.companyName}}</span>
          </div>
          <div class="top-money">

          </div>
     </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">公司名称:</span><span class="pl-content">{{listpar.companyName}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系人:</span><span class="pl-content">{{listpar.contacts}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系手机号:</span><span class="pl-content">{{listpar.cellPhone}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">公司地址:</span><span class="pl-content">{{listpar.company_addr}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">性别:</span><span class="pl-content">{{listpar.Gender}}</span></div> -->
          <!-- <div class="particulars-list" ><span class="pl-tltle">年龄:</span><span class="pl-content">{{listpar.age}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">注册时间:</span><span class="pl-content">{{listpar.registration}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">法人姓名:</span><span class="pl-content">{{listpar.lawer_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">统一社会信用代码:</span><span class="pl-content">{{listpar.uniformCreditCode}}</span></div>
      </div>

      <div class="particulars-img">
          <div class="particulars-list" >
            <span class="pl-tltle">营业执照:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.businessImage" alt="">
            </viewer>
            </div>
          <div class="particulars-list" >
            <span class="pl-tltle">派遣许可证:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.licenceImage" alt="">
             </viewer>
           </div>
          <div class="particulars-list" >
            <span class="pl-tltle">身份证正面:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.lawer_idcard_npic" alt="">
             </viewer>
           </div>
          <div class="particulars-list" >
            <span class="pl-tltle">身份证反面:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.lawer_idcard_ppic" alt="">
             </viewer>
            </div>
      </div>


      <div class="unamortizedpar-affirm">
          <div>
           <el-button type="primary" @click="dialogFormVisible = true" class="biy-icon" icon="el-icon-close">拒绝</el-button>
          </div>
          <div>
           <el-button type="primary" @click="tijiao()" class="biy-icon" icon="el-icon-check">提交</el-button>
          </div>
      </div>

      <el-dialog title="请输入拒绝理由" :visible.sync="dialogFormVisible" width="450px">
      <el-form :model="listpar" label-width="80px">
            <el-form-item label="拒绝理由" >
                  <el-input v-model="listpar.liyou"></el-input>
          </el-form-item>
      </el-form>
          <div div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="jujue()">确 定</el-button>
            </div>
        </el-dialog>



    </div>



  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      dialogFormVisible:false,

      listpar:{},
      list:fakelist.liste,
       logintype:this.$common.baseGetSeparate(),
    };
  },

  created(){
          this.getSong();
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'ServicerEntity',
        bizId:this.$route.params.id,
        })
   
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },


// 拒绝接口
    async jujue(){
        let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'ServicerEntity',
        handleMode:'audit',
        bizId:this.$route.params.id,
        todo:'refuse',//allow 同意/refuse 拒绝
        reason:this.listpar.liyou
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('驳回成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/merchantlist`});
          }
        });
      },

  //提交接口
     async tijiao(){
        let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'ServicerEntity',
        handleMode:'audit',
        bizId:this.$route.params.id,
        todo:'allow',//allow 同意/refuse 拒绝
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('成功同意', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/merchantlist`});
          }
        });
      },
  },
  computed: {},
};

</script>


<style  scoped>
/* 待审核商家按钮 */
.unamortizedpar-affirm{
    height: 70px;
    line-height: 70px;
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 100px 0 200px 0;
}
.unamortizedpar-affirm .biy-icon{
    width: 100px;
    border-radius: 20px;
}
@import "../../assets/css/parstyle.css";

</style>